(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("agGrid"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "agGrid"], factory);
	else if(typeof exports === 'object')
		exports["agGridVue"] = factory(require("vue"), require("agGrid"));
	else
		root["agGridVue"] = factory(root["Vue"], root["agGrid"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__8bd4__) {
return 